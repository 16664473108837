import React from 'react';
import {walletPage, walletHeader,walletCardsContainer} from '../styles/layout/wallet/index.module.scss';
import loadable from '@loadable/component'

const EthWallet = loadable(()=>import('../components/composite/wallet'));

const isBrowser = typeof window !== "undefined"


const Wallet = ()=> {

    return(
        <>
        <div className={walletPage}>
            <h2 className={walletHeader}>Wallet</h2>
            <div className={walletCardsContainer}>
            {isBrowser &&
             <React.Suspense fallback={null}>
                <EthWallet/>
             </React.Suspense>
            
            }
            </div> 
        </div>
        </>
    );
};


export default Wallet;